import { Dimension, WidgetTypes } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as PlayIcon } from "assets/icons/common/play-with-circle.svg";
import { ReactComponent as BlockIcon } from "assets/icons/sidebar/ui-blocks-icon.svg";
import { ReactComponent as WidgetSidebarIcon } from "assets/icons/sidebar/widget-sidebar-icon.svg";
import { PopperProps } from "components/ui/Popper";
import { useShowUIBlockPrompt } from "hooks/ui/useUiBlocks";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import {
  getFocusedWidget,
  getWidget,
  selectPageWidgetIsEmpty,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { useAppDispatch, useAppSelector } from "store/helpers";
import {
  setInsertionContext,
  showUiBlocksModal,
} from "store/slices/uiBlocks/slice";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";
import { AddWidgetPopup } from "../Explorer/Widgets/AddWidgetMenu";

const buttonStyle = css<{
  narrow?: boolean;
  themeColors: GeneratedTheme["colors"];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  pointer-events: auto;
  padding: ${({ narrow }) => (narrow ? "0px" : "8px 12px 8px 10px")};
  border-radius: 4px;

  white-space: nowrap;
  width: ${({ narrow }) => (narrow ? "32px" : undefined)};

  line-height: 16px;
  height: 32px;
  transition: background 0.2s;

  color: ${({ themeColors }) => themeColors.neutral700};
  border: 1px solid ${({ themeColors }) => themeColors.neutral100};
  background: ${({ themeColors }) => themeColors.neutral};
  &:hover {
    background: ${({ themeColors }) => themeColors.neutral25};
  }
  box-shadow:
    0px 4px 16px 0px #22272f14,
    0px 1px 3px 0px #22272f0f;

  svg {
    color: ${({ themeColors }) => themeColors.neutral700};
  }
`;

const ButtonContainer = styled.div<{
  narrow?: boolean;
  themeColors: GeneratedTheme["colors"];
}>`
  ${buttonStyle}
`;

const ComponentsButtonContainer = styled(ButtonContainer)`
  svg {
    path {
      stroke: ${({ themeColors }) => themeColors.neutral700};
    }
  }
`;

const PlayButtonContainer = styled(ButtonContainer)`
  svg {
    rect {
      fill: ${({ themeColors }) => themeColors.neutral};
    }
    path {
      stroke: ${({ themeColors }) => themeColors.neutral700};
    }
  }
`;

const containerShownClass = styleAsClass`
  pointer-events: auto;
  opacity: 1;
`;

const containerHiddenClass = styleAsClass`
  pointer-events: none;
  opacity: 0;
`;

const CTAContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: opacity 0.2s;
`;

const handleVideoClick = () => {
  window.open(DOCS_PAGE_URL(DocsPage.FIVE_MIN_GUIDE_URL), "_blank");
};

const popperSettings: Partial<PopperProps> = {
  placement: "left-start",
};

export function EmptyCanvasOverlay({
  widgetId,
  internalWidth,
}: {
  widgetId: string;
  internalWidth?: Dimension<"px">;
}) {
  const dispatch = useAppDispatch();
  const canInsertUIBlock = useShowUIBlockPrompt(widgetId);
  const widget = useAppSelector(
    useCallback((state) => getWidget(state, widgetId), [widgetId]),
  );
  const parentWidget = useAppSelector(
    useCallback(
      (state) => getWidget(state, widget?.parentId),
      [widget?.parentId],
    ),
  );
  const grandparentWidget = useAppSelector(
    useCallback(
      (state) => getWidget(state, parentWidget?.parentId),
      [parentWidget?.parentId],
    ),
  );
  const generatedTheme = useAppSelector(selectGeneratedTheme);
  const pageWidgetIsEmpty = useAppSelector(selectPageWidgetIsEmpty);

  const focusedWidget = useAppSelector(getFocusedWidget);

  const isFocused = useMemo(() => {
    // Focus happens on the container/tab widget, so we should check if it has focus
    if (
      (parentWidget?.type === WidgetTypes.CONTAINER_WIDGET ||
        parentWidget?.type === WidgetTypes.TABS_WIDGET ||
        parentWidget.type === WidgetTypes.SECTION_WIDGET) &&
      focusedWidget?.widgetId === parentWidget.widgetId
    ) {
      return true;
    }

    return focusedWidget?.widgetId === widgetId;
  }, [focusedWidget, parentWidget, widgetId]);

  const isDragging = useAppSelector(selectIsDragging);
  const isResizing = useAppSelector(selectIsResizing);

  const { sectionInsertionIndex, columnInsertionIndex } = useMemo(() => {
    const canvasIndex = parentWidget?.children?.indexOf(widgetId);
    const sectionIndex = grandparentWidget?.children?.indexOf(
      parentWidget?.widgetId ?? "",
    );

    return {
      sectionInsertionIndex:
        sectionIndex != null ? sectionIndex + 1 : undefined,
      columnInsertionIndex: canvasIndex != null ? canvasIndex + 1 : undefined,
    };
  }, [parentWidget, grandparentWidget, widgetId]);

  const anchorRef = useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement>;
  const [showAddWidgetPopup, setShowAddWidgetPopup] = useState(false);
  const handleClose = useCallback(() => {
    setShowAddWidgetPopup(false);
  }, []);

  const hasWidgets = widget?.children && widget.children.length > 0;
  const shouldShow = useMemo(() => {
    if (isDragging || isResizing || hasWidgets) {
      return false;
    }
    if (pageWidgetIsEmpty || showAddWidgetPopup) {
      return true;
    }
    return isFocused;
  }, [
    hasWidgets,
    pageWidgetIsEmpty,
    isFocused,
    isDragging,
    isResizing,
    showAddWidgetPopup,
  ]);

  const handleDragStart = useCallback(() => {
    setShowAddWidgetPopup(false);
  }, []);

  const handleUIBlockClick = useCallback(() => {
    dispatch(
      setInsertionContext({
        insertionTargetId: widgetId,
        cursorInsertionIndexes: {
          sectionInsertionPosition: sectionInsertionIndex,
          columnInsertionPosition: columnInsertionIndex,
        },
      }),
    );
    logger.info("UI Blocks: Opened modal from overlay");
    dispatch(showUiBlocksModal());
  }, [dispatch, widgetId, sectionInsertionIndex, columnInsertionIndex]);

  const templatesButton = useMemo(() => {
    if (!canInsertUIBlock) {
      return null;
    }
    const smallSize =
      (internalWidth && internalWidth.value < 540) || !pageWidgetIsEmpty;
    const container = (
      <ButtonContainer
        onClick={handleUIBlockClick}
        narrow={smallSize}
        themeColors={generatedTheme.colors}
      >
        <BlockIcon width="16px" height="16px" />
        {!smallSize && <span>Browse templates</span>}
      </ButtonContainer>
    );
    return smallSize ? (
      <Tooltip title="Browse UI Templates">{container}</Tooltip>
    ) : (
      container
    );
  }, [
    handleUIBlockClick,
    canInsertUIBlock,
    generatedTheme.colors,
    pageWidgetIsEmpty,
    internalWidth,
  ]);

  const componentsButton = useMemo(() => {
    const smallSize = internalWidth && internalWidth.value < 320;
    const container = (
      <ComponentsButtonContainer
        onClick={() => {
          setShowAddWidgetPopup(true);
        }}
        ref={anchorRef}
        narrow={smallSize}
        themeColors={generatedTheme.colors}
      >
        <WidgetSidebarIcon width="16px" height="16px" />
        {!smallSize && <span>Add component</span>}
      </ComponentsButtonContainer>
    );
    return smallSize ? (
      <Tooltip title="Add component">{container}</Tooltip>
    ) : (
      container
    );
  }, [anchorRef, internalWidth, generatedTheme.colors]);

  if (hasWidgets) {
    return null;
  }

  return (
    <CTAContainer
      className={shouldShow ? containerShownClass : containerHiddenClass}
    >
      {componentsButton}
      {templatesButton}
      {pageWidgetIsEmpty && (
        <Tooltip title="View 5 minute Quick Start Guide →">
          <PlayButtonContainer
            onClick={handleVideoClick}
            narrow
            themeColors={generatedTheme.colors}
          >
            <PlayIcon width="16px" height="16px" />
          </PlayButtonContainer>
        </Tooltip>
      )}
      <AddWidgetPopup
        isOpen={showAddWidgetPopup}
        anchorRef={anchorRef}
        onClose={handleClose}
        onDragStart={handleDragStart}
        popperProps={popperSettings}
        hideUIBlocks={true}
      />
    </CTAContainer>
  );
}
